import React, { useState, useEffect, lazy, Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { auth, onAuthStateChanged } from './firebase';
import { collection, query, onSnapshot } from "firebase/firestore";
import { firestore } from './firebase';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import Loading from './pages/components/loading';
import './App.css';
import Logo from './pages/components/708090.png';

// Convert regular imports to lazy imports
const LoginPage = lazy(() => import('./pages/loginPage'));
const DashboardPage = lazy(() => import('./pages/dashboardPage'));
const PricingPage = lazy(() => import('./pages/pricingPage'));
const EndUser = lazy(() => import('./pages/endUser'));
const Review = lazy(() => import('./pages/review'));
const NotificationPage = lazy(() => import('./pages/NotificationPage'));
const CancelledSubscription = lazy(() => import('./pages/CancelledSubscription'));

const NotificationPageWithTitle = () => {
  return (
    <>
      <Helmet>
        <title>Notificări | Servicegest</title>
        <meta name="description" content="Verifică notificările tale, ITP, CASCO, ROVINIETA, RCA în Servicegest." />
        <meta property="og:title" content="Notificări | Servicegest" />
        <meta property="og:image" content={Logo} />
        <meta property="og:type" content="website" />
        <meta property="og:description" content="Poți seta notificări pentru RCA, CASCO, ITP, ROVINIETA și alte servicii în mai puțin de 1 minut prin Servicegest." />        <meta property="og:locale" content="ro_RO" />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <NotificationPage />
    </>
  );
};
const CancelledSubscriptionWithTitle = () => {
  return (
    <>
      <Helmet>
        <title>Abonament anulat | Servicegest</title>
      </Helmet>
      <CancelledSubscription />
    </>
  );
}
const DashboardPageWithTitle = () => {
  return (
    <>
      <Helmet>
        <title>Dashboard | Servicegest</title>
      </Helmet>
      <DashboardPage />
    </>
  );
}
const LoginPageWithTitle = () => {
  return (
    <>
      <Helmet>
        <title>Autentificare | Servicegest</title>
      </Helmet>
      <LoginPage />
    </>
  );
}
const EndUserWithTitle = () => {
  const [jsonLdData, setJsonLdData] = useState(null);

  useEffect(() => {
    const fetchJsonLd = async () => {
      try {
        const response = await fetch('https://api.servicegest.ro/public/homeJsonLd');
        if (response.ok) {
          const data = await response.json();
          setJsonLdData(data);
        } else {
          console.error('Error fetching JSON-LD data, status:', response.status);
        }
      } catch (err) {
        console.error('Error fetching JSON-LD data:', err);
      }
    };

    fetchJsonLd();
  }, []);

  return (
    <>
      <Helmet>
        <title>ITP Cluj | Programează-te online cu Servicegest</title>
        <meta name="description" content="Alege stația ITP din Cluj care ți se potrivește. Te poți programa la o stație itp din Cluj în doar 2 minute." />
        <meta property="og:title" content="ITP Cluj | Programează-te online cu Servicegest" />
        <meta property="og:image" content={Logo} />
        <meta property="og:type" content="website" />
        <meta property="og:description" content="Te poți programa la ITP în Cluj în mai puțin de 2 minute. Alegi dintr-o listă de stații în funcție de preferințele tale" />
        <meta property="og:locale" content="ro_RO" />
        <meta name="robots" content="index, follow" />
        
        {jsonLdData && (
          <script type="application/ld+json">
            {JSON.stringify(jsonLdData)}
          </script>
        )}
      </Helmet>
      <EndUser />
    </>
  );
}
const PricingPageWithTitle = () => {
  return (
    <>
      <Helmet>
        <title>Prețuri | Servicegest</title>
        <meta name="description" content="Descoperă prețurile competitive pentru serviciile oferite de Servicegest, inclusiv ITP, RCA, CASCO, și ROVINIETA. Economisește timp și bani cu cele mai bune oferte." />
        <meta property="og:title" content="Prețuri | Servicegest" />
        <meta property="og:image" content={Logo} />
        <meta property="og:type" content="website" />
        <meta property="og:description" content="Descoperă prețurile competitive pentru serviciile oferite de Servicegest, inclusiv ITP, RCA, CASCO, și ROVINIETA. Economisește timp și bani cu cele mai bune oferte." />
        <meta property="og:locale" content="ro_RO" />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <PricingPage />
    </>
  );
}
const App = () => {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const [isCancelled, setIsCancelled] = useState(false);

  useEffect(() => {
    const unsubscribeAuth = onAuthStateChanged(auth, async (user) => {
      if (!user) {
        setUser(null);
        setUserRole(null);
        setIsCancelled(false);
        setLoading(false);
        return;
      }
  
      setUser(user);
      setLoading(true);
  
      const subsQuery = query(collection(firestore, "customers", user.uid, "subscriptions"));
      const unsubscribeSubs = onSnapshot(subsQuery, (subsSnapshot) => {
        let hasActiveSubscription = false;
        let hasCancelledSubscription = false;
  
        subsSnapshot.forEach((doc) => {
          const subData = doc.data();
          
          if (subData.status === 'active' || subData.status === 'trialing') {
            hasActiveSubscription = true;
          }
          if (subData.status === 'canceled') { // Adjust based on how Stripe marks canceled subscriptions
            hasCancelledSubscription = true;
          }
        });
  
        setUserRole(hasActiveSubscription ? 'premium' : 'standard');
        setIsCancelled(hasCancelledSubscription);
  
        setTimeout(() => setLoading(false), 200);
      });
  
      return () => unsubscribeSubs();
    });
  
    return () => unsubscribeAuth();
  }, []);
  

  if (loading) {
    return <Loading />;
  }

  return (
    <HelmetProvider>
      <Router>
        <Suspense fallback={<Loading />}>
          <Routes>
            <Route path="/login" element={<LoginPageWithTitle />} />
            <Route path="/pricing" element={<PricingPageWithTitle />} />
            <Route path="/cancelled" element={<CancelledSubscriptionWithTitle />} />
            <Route path="/review" element={<Review />} />
            <Route path="/loading" element={<Loading />} />

            <Route 
              path="/dashboard/*" 
              element={
                user ? (
                  isCancelled ? (
                    <Navigate to="/cancelled" replace />
                  ) : userRole === 'premium' ? (
                    <DashboardPageWithTitle />
                  ) : (
                    <Navigate to="/pricing" replace />
                  )
                ) : (
                  <Navigate to="/login" replace />
                )
              }
            />
            <Route path="/*" element={<LoginPageWithTitle />} />
          </Routes>
        </Suspense>
      </Router>
    </HelmetProvider>
  );
};

export default App;